<template>
  <div>

    <div class="row" v-permission="['contract.create','contract.edit']">
      <div class="col-md-12">



        <KTPortlet
            v-bind="{
            }"
            v-bind:class="{ 'kt-spinner-f': loading }"
        >
          <template v-slot:title>
            <h4 class="">Configurazione</h4>
          </template>
          <template v-slot:body>
            <!--                        <b-form-group-->
            <!--                                label-cols="4"-->
            <!--                                label-cols-lg="2"-->
            <!--                                label-size="sm"-->
            <!--                                label="Small"-->
            <!--                                label-for="input-sm"-->
            <!--                        >-->
            <!--                            <b-form-input id="input-sm" size="sm"></b-form-input>-->
            <!--                        </b-form-group>-->
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Nome"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.firstname"
                    type="text"
                    required
                    placeholder="Inserisci il Nome"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Cognome"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.lastname"
                    type="text"
                    required
                    placeholder="Inserisci il Cognome"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Email"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.email"
                    type="text"
                    required
                    placeholder="Inserisci la Email"
                    :readonly="!isNew()&&!canChangeAll()"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Password"
                  label-for="label"
                  v-if="isNew()"
              >
                <b-form-input
                    id="label"
                    v-model="form.password"
                    type="text"
                    required
                    placeholder="Inserisci la Password"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  v-if="canChangeAll()"
                  label-cols="4"
                  label-cols-lg="2"
                  label="Abilita"
                  label-for="label"
              >
                <b-form-checkbox value="1" v-model="form.enabled" class="mb-2 mr-sm-2 mb-sm-0">
                  <!--                                Abilita Reminder-->
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                  v-if="canChangeAll()"
                  label-cols="4"
                  label-cols-lg="2"
                  label="Abilita interfaccia"
                  label-for="label"
              >
                <b-form-checkbox value="1"  v-model="form.ui_enabled" class="mb-2 mr-sm-2 mb-sm-0">
                  <!--                                Abilita Reminder-->
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                  v-if="canChangeAll()"
                  label-cols="4"
                  label-cols-lg="2"
                  label="Abilita API"
                  label-for="label"
              >
                <b-form-checkbox value="1"  v-model="form.api_enabled" class="mb-2 mr-sm-2 mb-sm-0">
                  <!--                                Abilita Reminder-->
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                  v-if="canChangeAll()"
                  label-cols="4"
                  label-cols-lg="2"
                  label="ID Organizzazione"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.organization_id"
                    type="text"
                    required
                    placeholder="ID Organizzazione"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  v-if="!isNew()"
                  label-cols="4"
                  label-cols-lg="2"
                  label="Cambia password"
                  label-for="label"
              >
                <b-form-checkbox value="1"  v-model="change_pwd" class="mb-2 mr-sm-2 mb-sm-0">
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                  v-if="!isNew() && change_pwd"
                  label-cols="4"
                  label-cols-lg="2"
                  label="Nuova password"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.new_password"
                    :type="newpasswordtype"
                    required
                    placeholder="Nuova password"
                ></b-form-input>
                <v-icon
                    @click="newpasswordtype = newpasswordtype=='text'?'password':'text'"
                    small
                    class="mr-2"
                >
                  mdi-eye
                </v-icon>
              </b-form-group>


              <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
              <b-button type="reset" variant="danger">Indietro</b-button>

            </b-form>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../../store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UserService from "../../../../common/user.service";
import ApiService from "../../../../common/api.service";
import Vue from "vue";

export default {
  data() {
    return {
      loading:false,
      change_pwd:false,
      newpasswordtype:'password',
      formoptions:{
      },
      form: {
      },

    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Utenti", route: "/setting/user/list" },
      { title: !this.isNew()?"Modifica utente":'Nuovo utente' }
    ]);
  },

  created () {
    this.initialize()
  },
  methods:{
    isNew: function(){
      return !this.currentEditId();
    },
    currentEditId: function(){
      return this.$router.currentRoute.params.id;
    },
    canChangeAll: function(){
      return UserService.hasPermission('super');
    },
    onSubmit(evt) {
      evt.preventDefault()
      //alert(JSON.stringify(this.form))
      this.loading=true;
      let datasend = this.form;
      datasend.fistname = this.form.firstname;
      if(!this.change_pwd||!this.form.new_password){
        delete(datasend['new_password']);
      }
      ApiService.post('users'+(this.isNew()?'':'/'+this.currentEditId()),datasend)
          .then(({data}) => {
            if(this.isNew()){
              Vue.customNotifySuccess('Salvato');
              this.$router.push({ name: "setting-user-edit", 'params':{'id':data.id} });
              this.loading = false;
              this.reload();
            }else {
              Vue.customNotifySuccess('Salvato');
              this.loading = false;
              this.reload();
            }

          })
          .catch(({response}) => {
            Vue.customNotifyError('Errore',response.data&&response.data.errors?response.data.errors.join(', '):'Si prega di riprovare');
          });
    },
    onReset(evt) {
      this.$router.go(-1);

    },
    initialize () {
      this.reload();
    },
    reload(){
      const that = this;
      if(!this.isNew()) {
        this.loading=true;
        ApiService.get('users/'+this.currentEditId())
            .then(({data}) => {

              this.form = data;

              this.loading=false;
            })
            .catch(({response}) => {
              //context.commit(SET_ERROR, response.data.errors);
            });
      }else{
        this.form.enabled = 1;
        this.form.ui_enabled = 1;
        this.form.api_enabled = 1;
        this.form.organization_id = this.$store.getters.currentUser.organization_id;
      }
    },
  }
};
</script>
